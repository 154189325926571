<template>
  <div>
    <div>
      <el-table :data="VRRport" border style="width: 100%">
        <el-table-column
          prop="cname"
          label="患者姓名"
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="rtName"
          label="报告类型"
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="dateTime"
          label="报告时间"
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column
          label="报告结果"
          prop="solution"
          align="center"
          width="220"
        >
        </el-table-column>
        <el-table-column label="身份证号" align="center" width="220"
          ><template slot-scope="scope">{{
            scope.row.cid | hideMiddle
          }}</template>
        </el-table-column>
        <el-table-column prop="rtId" label="机位" align="center" width="270">
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="300" align="center">
          <template slot-scope="scope">
            <el-button
              @click="delBnt(scope.row.id)"
              size="mini"
              type="danger"
              icon="el-icon-circle-close"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import Page from "./Page.vue";
import { createNamespacedHelpers } from "vuex";
import axios from "../http/axios";
import api from "../http/api";
const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  "patient"
);

export default {
  created() {
    this.getReoprtALl();
  },
  filters: {
    hideMiddle(val) {
      return `${val.substring(0, 3)}****${val.substring(val.length - 3)}`;
    },
  },
  computed: {
    ...mapState(["VRRport"]),
  },
  methods: {
    ...mapActions(["getReoprtALl", "delVRone"]),

    // 删除患者
    delBnt(id) {
      this.getReoprtALl();
      // 删除提示框
      this.$confirm("此操作将永久删除该数据，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "danger",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功！",
          });

          this.delVRone(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style>
</style>
